import './App.css';
import ThankYouPage from './pages/thankYou';
import HomePage from "./pages/homepage";
import LandingPage from "./pages/landingPage";
import ContactUs from "./pages/contactUs";
import Blog from "./pages/blog";
import Portfolio from "./pages/portfolio";
import CurrentProject from "./pages/services/currentProject";
import Consultancy from "./pages/services/consultancy";
import ProjectSelling from "./pages/services/projectSelling";
import PropertyManagement from "./pages/services/propertyManagement";
import Transactions from "./pages/services/transaction";
import Turnkey from "./pages/services/turnkey";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./pages/scrollTop";
function App() {
  return (
    <div className="App">
  <BrowserRouter>
  <ScrollToTop />
          <Routes>
            <Route path="/home" element={<HomePage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/currentProject" element={<CurrentProject />} />
            <Route path="/consultancy" element={<Consultancy />} />
            <Route path="/projectSelling" element={<ProjectSelling />} />
            <Route path="/propertyManagement" element={<PropertyManagement />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/turnkey" element={<Turnkey />} />
            <Route path="/thankYou" element={<ThankYouPage />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
