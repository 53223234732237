import React, {} from 'react';
import '../../css/portfolio.css'
import '../../css/propertyManagement.css'
import NavBar from '../../layout/header';
import Footer from '../../layout/footer';
const PropertyManagement = () => {

  return (
    <div className='overflow-hidden'>
        <div className='contactUs-bg'>
    <NavBar/>
    <div className="dlab-bnr-inr d-flex align-items-center">
        <div className="container">
        <div className="dlab-bnr-inr-entry text-md-start text-center">
        <h1>Property Management</h1>
    <nav aria-label="breadcrumb" className="breadcrumb-row d-flex d-md-block justify-content-center">
        <ul className="breadcrumb">
        <li className="breadcrumb-item"><a href=""><i className="las fa-cogs me-2"></i>Services</a></li>
        <li className="breadcrumb-item active" aria-current="page">Property Management</li>
    </ul>
    </nav>
    </div>
    </div>
    </div>
     </div>
     <div className="why-choose-us mt-5">
  <div className="container">
    <h2 className="text-center mb-4">Why Choose HomeOn</h2>
    <div className="row">
      <div className="col-lg-6 mb-4">
        <img src="assets/buying-a-home.jpeg" alt="Why Choose Us" className="img-fluid rounded"/>
      </div>
      <div className="col-lg-6">
      <ul className="benefits-list">
  <li>Trusted by hundreds of property owners across Pakistan.</li>
  <li>Experienced team with years of expertise in property management.</li>
  <li>Transparent processes with real-time updates.</li>
  <li>Affordable rates with no hidden charges.</li>
  <li>Exclusive services tailored for overseas Pakistani investors.</li>
  <li>Real-time virtual property updates and progress reports.</li>
  <li>24/7 support to accommodate different time zones.</li>
  <li>End-to-end solutions, from property purchase to tenant management.</li>
  <li>Secure handling of transactions and property-related legal documentation.</li>
</ul>
      </div>
    </div>
  </div>
</div>
<div className="services-section py-5 why-choose-us">
  <div className="container">
    <h2 className="text-center mb-4">Our Property Management Services</h2>
    <div className="row">
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="service-card">
          <i className="las la-user-tie"></i>
          <h4>Tenant Management</h4>
          <p>Find, vet, and manage tenants for your property seamlessly.</p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="service-card">
          <i className="las la-tools"></i>
          <h4>Maintenance & Repairs</h4>
          <p>Timely maintenance and repairs to keep your property in top condition.</p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="service-card">
          <i className="las la-wallet"></i>
          <h4>Rent Collection</h4>
          <p>Automated rent collection and financial management services.</p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="service-card">
          <i className="las la-handshake"></i>
          <h4>Property Selling Assistance</h4>
          <p>Expert guidance to market and sell your property at the best price.</p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="service-card">
          <i className="las la-hard-hat"></i>
          <h4>Construction Management</h4>
          <p>Oversee construction projects from start to finish with expert supervision.</p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="service-card">
          <i className="las la-chart-pie"></i>
          <h4>Investment Consultancy</h4>
          <p>Comprehensive advice to help you make informed real estate investments.</p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="service-card">
          <i className="las la-building"></i>
          <h4>Property Leasing</h4>
          <p>Efficient leasing services to ensure high occupancy rates.</p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="service-card">
          <i className="las la-file-alt"></i>
          <h4>Legal Assistance</h4>
          <p>Documentation and legal support for hassle-free property transactions.</p>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="service-card">
          <i className="las la-cogs"></i>
          <h4>Customized Solutions</h4>
          <p>Tailored solutions to meet unique property management requirements.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div className=" container mt-5 property-hero">
  <div className="container text-center">
    <h2 className="hero-heading">Comprehensive Property Management</h2>
    <p className="hero-text">Your property, our responsibility. Experience seamless management and maximize returns with HomeOn.</p>
    <button href="/contact" className="proj-selling-btn">Get Started</button>
  </div>
</div>

     <div className='whatsapp-position-custom'>
     <a href="https://wa.me/923255255255" className='whatsapp' target='_blank'>
          <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="70"
      height="70"
      viewBox="0 0 48 48"
      className="whatsapp-logo"
    >
      <path
        fill="#fff"
        d="M4.9,43.3l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5c5.1,0,9.8,2,13.4,5.6	C41,14.2,43,18.9,43,24c0,10.5-8.5,19-19,19c0,0,0,0,0,0h0c-3.2,0-6.3-0.8-9.1-2.3L4.9,43.3z"
      ></path>
      <path
        fill="#fff"
        d="M4.9,43.8c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.3-0.1-0.5L7,33.5c-1.6-2.9-2.5-6.2-2.5-9.6	C4.5,13.2,13.3,4.5,24,4.5c5.2,0,10.1,2,13.8,5.7c3.7,3.7,5.7,8.6,5.7,13.8c0,10.7-8.7,19.5-19.5,19.5c-3.2,0-6.3-0.8-9.1-2.3	L5,43.8C5,43.8,4.9,43.8,4.9,43.8z"
      ></path>
      <path
        fill="#40c351"
        d="M24,5c5.1,0,9.8,2,13.4,5.6C41,14.2,43,18.9,43,24c0,10.5-8.5,19-19,19h0c-3.2,0-6.3-0.8-9.1-2.3	L4.9,43.3l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5 M24,43L24,43L24,43 M24,43L24,43L24,43 M24,4L24,4C13,4,4,13,4,24	c0,3.4,0.8,6.7,2.5,9.6L3.9,43c-0.1,0.3,0,0.7,0.3,1c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.2,0,0.3,0l9.7-2.5c2.8,1.5,6,2.2,9.2,2.2	c11,0,20-9,20-20c0-5.3-2.1-10.4-5.8-14.1C34.4,6.1,29.4,4,24,4L24,4z"
      ></path>
      <path
        fill="#40c351"
        d="M35.2,12.8c-3-3-6.9-4.6-11.2-4.6C15.3,8.2,8.2,15.3,8.2,24c0,3,0.8,5.9,2.4,8.4L11,33l-1.6,5.8	l6-1.6l0.6,0.3c2.4,1.4,5.2,2.2,8,2.2h0c8.7,0,15.8-7.1,15.8-15.8C39.8,19.8,38.2,15.8,35.2,12.8z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M19.3,16c-0.4-0.8-0.7-0.8-1.1-0.8c-0.3,0-0.6,0-0.9,0	s-0.8,0.1-1.3,0.6c-0.4,0.5-1.7,1.6-1.7,4s1.7,4.6,1.9,4.9s3.3,5.3,8.1,7.2c4,1.6,4.8,1.3,5.7,1.2c0.9-0.1,2.8-1.1,3.2-2.3	c0.4-1.1,0.4-2.1,0.3-2.3c-0.1-0.2-0.4-0.3-0.9-0.6s-2.8-1.4-3.2-1.5c-0.4-0.2-0.8-0.2-1.1,0.2c-0.3,0.5-1.2,1.5-1.5,1.9	c-0.3,0.3-0.6,0.4-1,0.1c-0.5-0.2-2-0.7-3.8-2.4c-1.4-1.3-2.4-2.8-2.6-3.3c-0.3-0.5,0-0.7,0.2-1c0.2-0.2,0.5-0.6,0.7-0.8	c0.2-0.3,0.3-0.5,0.5-0.8c0.2-0.3,0.1-0.6,0-0.8C20.6,19.3,19.7,17,19.3,16z"
        clipRule="evenodd"
      ></path>
    </svg>
    </a>
    </div>
<Footer/>
</div>
  );
};

export default PropertyManagement;
