import React, {} from 'react';
import '../../css/portfolio.css'
import '../../css/transaction.css'
import NavBar from '../../layout/header';
import Footer from '../../layout/footer';
const Transactions = () => {

  return (
    <div className='overflow-hidden'>
        <div className='contactUs-bg'>
    <NavBar/>
    <div className="dlab-bnr-inr d-flex align-items-center">
        <div className="container">
        <div className="dlab-bnr-inr-entry text-md-start text-center">
        <h1>Transactions</h1>
    <nav aria-label="breadcrumb" className="breadcrumb-row d-flex d-md-block justify-content-center">
        <ul className="breadcrumb">
        <li className="breadcrumb-item"><a href=""><i className="las fa-cogs me-2"></i>Services</a></li>
        <li className="breadcrumb-item active" aria-current="page">Transactions</li>
    </ul>
    </nav>
    </div>
    </div>
    </div>
     </div>
     <div className="transactions-section why-choose-us mt-5">
  <div className="container">
    <h2 className="text-center">Seamless Property Transactions</h2>
    <p className="text-center specification-text">We ensure that property transactions are as smooth as possible. Our team handles everything from paperwork to legal formalities, making the process hassle-free.</p>
    <div className="row mt-4">
      <div className="col-md-6">
        <div className="transaction-card">
          <i className="las la-file-alt"></i>
          <h4>Documentation & Paperwork</h4>
          <p>Our experts assist in preparing and managing all property-related documents to ensure compliance with legal requirements.</p>
        </div>
      </div>
      <div className="col-md-6 mt-md-0 mt-3">
        <div className="transaction-card">
          <i className="las la-clipboard-check"></i>
          <h4>Contract Management</h4>
          <p>We take care of the contract drafting and negotiations, ensuring all parties are aligned with the terms of the agreement.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="secure-transactions why-choose-us mt-5">
  <div className="container">
    <h2 className="text-center">Secure Transactions</h2>
    <p className="text-center specification-text">We prioritize the security of your transactions. Our platform employs advanced security protocols to ensure your financial information is protected at all times.</p>
    <div className="row">
      <div className="col-md-6">
        <div className="secure-card">
          <i className="las la-lock"></i>
          <h4>Encrypted Payments</h4>
          <p>All transactions are processed through secure, encrypted payment gateways to guarantee the safety of your funds.</p>
        </div>
      </div>
      <div className="col-md-6 mt-md-0 mt-3">
        <div className="secure-card">
          <i className="las la-shield-alt"></i>
          <h4>Fraud Protection</h4>
          <p>We implement strict anti-fraud measures to protect you from any unauthorized transactions or activities.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="payment-flexibility why-choose-us mt-5">
  <div className="container">
    <h2 className="text-center">Flexible Payment Options</h2>
    <p className="text-center specification-text">We offer multiple payment options tailored to your convenience, including installment plans, bank transfers, and online payment gateways.</p>
    <div className="row">
      <div className="col-md-6">
        <div className="payment-card">
          <i className="las la-credit-card"></i>
          <h4>Installment Plans</h4>
          <p>Flexible installment plans that fit your budget, allowing you to manage your investment easily over time.</p>
        </div>
      </div>
      <div className="col-md-6 mt-md-0 mt-3">
        <div className="payment-card">
          <i className="las la-exchange-alt"></i>
          <h4>Bank Transfers & Online Payments</h4>
          <p>Convenient and secure bank transfer options, as well as integration with leading online payment gateways.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="international-transactions why-choose-us mt-5">
  <div className="container">
    <h2 className="text-center">International Transactions</h2>
    <p className="text-center specification-text">For our overseas clients, we make property transactions easy and efficient with international payment options, minimizing the barriers to investment.</p>
    <div className="row">
      <div className="col-md-6">
        <div className="international-card">
          <i className="las la-globe"></i>
          <h4>Global Payment Integration</h4>
          <p>We support a range of international payment methods, enabling seamless property transactions from any part of the world.</p>
        </div>
      </div>
      <div className="col-md-6 mt-md-0 mt-3">
        <div className="international-card">
          <i className="las la-check-circle"></i>
          <h4>Currency Conversion Assistance</h4>
          <p>We provide real-time currency conversion assistance for overseas clients to ensure transparent financial transactions.</p>
        </div>
      </div>
    </div>
  </div>
</div>

     <div className='whatsapp-position-custom'>
     <a href="https://wa.me/923255255255" className='whatsapp' target='_blank'>
          <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="70"
      height="70"
      viewBox="0 0 48 48"
      className="whatsapp-logo"
    >
      <path
        fill="#fff"
        d="M4.9,43.3l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5c5.1,0,9.8,2,13.4,5.6	C41,14.2,43,18.9,43,24c0,10.5-8.5,19-19,19c0,0,0,0,0,0h0c-3.2,0-6.3-0.8-9.1-2.3L4.9,43.3z"
      ></path>
      <path
        fill="#fff"
        d="M4.9,43.8c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.3-0.1-0.5L7,33.5c-1.6-2.9-2.5-6.2-2.5-9.6	C4.5,13.2,13.3,4.5,24,4.5c5.2,0,10.1,2,13.8,5.7c3.7,3.7,5.7,8.6,5.7,13.8c0,10.7-8.7,19.5-19.5,19.5c-3.2,0-6.3-0.8-9.1-2.3	L5,43.8C5,43.8,4.9,43.8,4.9,43.8z"
      ></path>
      <path
        fill="#40c351"
        d="M24,5c5.1,0,9.8,2,13.4,5.6C41,14.2,43,18.9,43,24c0,10.5-8.5,19-19,19h0c-3.2,0-6.3-0.8-9.1-2.3	L4.9,43.3l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5 M24,43L24,43L24,43 M24,43L24,43L24,43 M24,4L24,4C13,4,4,13,4,24	c0,3.4,0.8,6.7,2.5,9.6L3.9,43c-0.1,0.3,0,0.7,0.3,1c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.2,0,0.3,0l9.7-2.5c2.8,1.5,6,2.2,9.2,2.2	c11,0,20-9,20-20c0-5.3-2.1-10.4-5.8-14.1C34.4,6.1,29.4,4,24,4L24,4z"
      ></path>
      <path
        fill="#40c351"
        d="M35.2,12.8c-3-3-6.9-4.6-11.2-4.6C15.3,8.2,8.2,15.3,8.2,24c0,3,0.8,5.9,2.4,8.4L11,33l-1.6,5.8	l6-1.6l0.6,0.3c2.4,1.4,5.2,2.2,8,2.2h0c8.7,0,15.8-7.1,15.8-15.8C39.8,19.8,38.2,15.8,35.2,12.8z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M19.3,16c-0.4-0.8-0.7-0.8-1.1-0.8c-0.3,0-0.6,0-0.9,0	s-0.8,0.1-1.3,0.6c-0.4,0.5-1.7,1.6-1.7,4s1.7,4.6,1.9,4.9s3.3,5.3,8.1,7.2c4,1.6,4.8,1.3,5.7,1.2c0.9-0.1,2.8-1.1,3.2-2.3	c0.4-1.1,0.4-2.1,0.3-2.3c-0.1-0.2-0.4-0.3-0.9-0.6s-2.8-1.4-3.2-1.5c-0.4-0.2-0.8-0.2-1.1,0.2c-0.3,0.5-1.2,1.5-1.5,1.9	c-0.3,0.3-0.6,0.4-1,0.1c-0.5-0.2-2-0.7-3.8-2.4c-1.4-1.3-2.4-2.8-2.6-3.3c-0.3-0.5,0-0.7,0.2-1c0.2-0.2,0.5-0.6,0.7-0.8	c0.2-0.3,0.3-0.5,0.5-0.8c0.2-0.3,0.1-0.6,0-0.8C20.6,19.3,19.7,17,19.3,16z"
        clipRule="evenodd"
      ></path>
    </svg>
    </a>
    </div>
<Footer/>
</div>
  );
};

export default Transactions;
