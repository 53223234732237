import React, { useState } from 'react';
import '../css/landingPage.css'
import { Link, NavLink } from 'react-router-dom';
const LandingPage = () => {
	const [showButtons, setShowButtons] = useState(false);

const toggleButtons = () => {
	setShowButtons(!showButtons);
};
  return (
    <div>
				<div className="page-wraper">
					<div className="page-content">
						<section className="welcome-screen">
							<video autoPlay loop muted  id="video-background" >
								<source src="assets/video/video-4.mp4" type="video/mp4" />
							</video>
							<div className="container-fluid">
                                <div className='row px-lg-4 px-0'>
                                    <div className='col-md-6 col-3'>
                                    <div className="welcome-header d-flex justify-content-between">
									<div className="wlcm-logo mb-5 mt-3">
										<NavLink to={'/home'}>
										<img src="assets/logo.png" alt=""/>
										</NavLink>
									</div>
								</div>
                                    </div>
                                    <div className='col-md-6 col-9 d-flex justify-content-end'>
									<ul className='contact-button-2 d-flex align-items-center'>
									<div className=''style={{ display: showButtons ? 'block' : 'none' }}>
		   <ul className='d-flex gap-md-4 gap-2'>
		    <li>
                <a href="#" className='phone' data-toggle="tooltip" title="Call Me Back">
                    <i className="fa fa-phone"></i>
                </a>
            </li>
            <li>
                <a href="https://wa.me/923255255255" className='whatsapp' target='_blank' data-toggle="tooltip" title="Whats App">
                    <i className="fa fa-whatsapp"></i>
                </a>
            </li>
            <li>
                <a href="#" className='enquire' data-toggle="tooltip" title="Enquire Now">
                    <i className="fa fa-commenting"></i>
                </a>
            </li>
            <li>
                <a href="#" className='facebook' data-toggle="tooltip" title="Facebook">
                    <i className="fa fa-facebook"></i>
                </a>
            </li>
			</ul>
			</div>
            <li className="contact-btn ms-md-3 ms-2">
                <a href="#" className={` ${showButtons ? 'show' : ''}`} onClick={toggleButtons}> 
                    <i className="fa fa-phone"></i>
                </a>
            </li>
        </ul>
									</div>
                                </div>
							</div>
							<div className="container-fluid wlcm-form">
                                    <div className='row px-lg-4 px-0'>
                                        <div className='col-md-6 col-12'>
                                        <div className="enter-form">
										<form >
											<h2 className="wlcm-form-title">Enter Your Credentials</h2>
											<div className="input-group form-group">
												<div className="input-group-prepend">
													<span className="las la-user"></span>
												</div>
												<input type="text" className="form-control" placeholder="Your Name" aria-label="Your Name"/>
											</div>
											<div className="input-group form-group">
												<div className="input-group-prepend">
													<span className="las las la-tty"></span>
												</div>
												<input type="text" className="form-control" placeholder="Phone No." aria-label="Phone No."/>
											</div>
											<div className="custom-control custom-checkbox form-group">
                                            <input class="form-check-input custom-check-box" type="checkbox" value="" id="flexCheckDefault"/>
												<label className="custom-control-label ps-3" for="customControlAutosizing">Subscribe to our newsletter</label>
											</div>
											<button type="submit" className="btn-custom">Submit</button>
										</form>
									</div>
                                        </div>
                                        <div className='col-md-6 col-12 landing-page-enter mt-md-0 mt-5'>
                                        <div className="enter-button landing-page-enter1">
										<Link to={'/home'} className="btn-custom2">Enter
											<div className="enter-animate">
											   <div className="arrow">
											   <i className="fa fa-chevron-down"></i>
												</div>
												<div className="arrow">
											   <i className="fa fa-chevron-down"></i>
												</div>
											</div>
										</Link>
									</div>
                                        </div>
                                    </div>
								 </div> 
						</section>
					</div>
				</div>
</div>
  );
};

export default LandingPage;
